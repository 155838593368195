<template>
  <svg width="47" height="57" viewBox="0 0 47 57" fill="none" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect x="0.800781" y="0.589844" width="45.3196" height="56.1644" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1" viewBox="0 0 1 1">
        <use xlink:href="#image0" transform="translate(-0.800781)" />
      </pattern>
      <image id="image0" width="1" height="1" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" />
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'UploadSVG',
}
</script>
