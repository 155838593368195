<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
    <circle
      v-if="active"
      cx="28.5"
      cy="28.5"
      r="28.5"
      fill="rgba(202, 82, 86, 0.2)"
    />
    <circle v-else cx="28.5" cy="28.5" r="28.5" fill="#D2D2D2" />
    <path
      d="M34.9578 38.355H23.2371V36.2555H33.9111V17.0253H13.8046V36.2555H15.7033V38.355H12.7518C12.1737 38.355 11.7051 37.8864 11.7051 37.3022V15.9786C11.7051 15.4005 12.1737 14.9258 12.7518 14.9258H34.9578C35.5359 14.9258 36.0045 15.3944 36.0045 15.9786V37.3083C36.0045 37.8864 35.5359 38.355 34.9578 38.355Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M52.108 38.3619H48.8218V36.2624H51.0552V31.3088H48.98C48.5784 31.3088 48.2133 31.0776 48.0368 30.7186L44.8967 24.2557H39.0546V36.2624H41.288V38.3619H38.0018C37.4236 38.3619 36.9551 37.8934 36.9551 37.3092V23.209C36.9551 22.6309 37.4236 22.1562 38.0018 22.1562H45.5539C45.9555 22.1562 46.3207 22.3875 46.4971 22.7465L49.6373 29.2093H52.108C52.6861 29.2093 53.1547 29.6779 53.1547 30.2621V37.3152C53.1547 37.8934 52.6861 38.3619 52.108 38.3619Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path d="M48.9783 29.207H38V31.3065H48.9783V29.207Z" :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M19.4642 42.2683C16.8049 42.2683 14.6445 40.108 14.6445 37.4486C14.6445 34.7893 16.8049 32.6289 19.4642 32.6289C22.1236 32.6289 24.284 34.7893 24.284 37.4486C24.284 40.108 22.1236 42.2683 19.4642 42.2683ZM19.4642 34.7284C17.9672 34.7284 16.744 35.9455 16.744 37.4486C16.744 38.9457 17.9611 40.1688 19.4642 40.1688C20.9674 40.1688 22.1845 38.9517 22.1845 37.4486C22.1845 35.9516 20.9613 34.7284 19.4642 34.7284Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M45.0541 42.2683C42.3947 42.2683 40.2344 40.108 40.2344 37.4486C40.2344 34.7893 42.3947 32.6289 45.0541 32.6289C47.7135 32.6289 49.8738 34.7893 49.8738 37.4486C49.8738 40.108 47.7074 42.2683 45.0541 42.2683ZM45.0541 34.7284C43.5571 34.7284 42.3339 35.9455 42.3339 37.4486C42.3339 38.9457 43.551 40.1688 45.0541 40.1688C46.5511 40.1688 47.7743 38.9517 47.7743 37.4486C47.7743 35.9516 46.5511 34.7284 45.0541 34.7284Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M12.8069 16.295C14.0422 15.5648 15.6305 16.1429 16.1052 17.4939C16.3791 18.2789 17.0606 18.8448 17.8822 18.9848C19.294 19.2161 20.146 20.6827 19.6409 22.0276C19.3488 22.8065 19.5009 23.6767 20.0425 24.3096C20.9736 25.3989 20.6815 27.0664 19.434 27.7662C18.7098 28.1739 18.2656 28.9407 18.2777 29.7683C18.296 31.1984 16.9937 32.2877 15.5879 32.026C14.7725 31.8739 13.9388 32.1782 13.4093 32.8172C12.5026 33.9247 10.8047 33.9247 9.89799 32.8172C9.36855 32.1782 8.53485 31.8739 7.71939 32.026C6.31364 32.2877 5.01133 31.1984 5.02959 29.7683C5.04176 28.9407 4.59752 28.1678 3.87335 27.7662C2.62582 27.0664 2.32763 25.3928 3.2648 24.3096C3.80641 23.6828 3.95855 22.8065 3.66644 22.0276C3.16135 20.6888 4.00722 19.2161 5.42514 18.9848C6.24669 18.8509 6.92219 18.2789 7.20212 17.4939C7.67679 16.1429 9.2712 15.5648 10.5005 16.295C11.2064 16.7149 12.0949 16.7149 12.8069 16.295Z"
      fill="url(#paint0_linear_6103_69698)" />
    <path
      d="M11.6507 30.817C8.34017 30.817 5.65039 28.1272 5.65039 24.8167C5.65039 21.5062 8.34017 18.8164 11.6507 18.8164C11.955 18.8164 12.2106 19.0659 12.2106 19.3763C12.2106 19.6866 11.961 19.9361 11.6507 19.9361C8.95481 19.9361 6.76403 22.1269 6.76403 24.8228C6.76403 27.5187 8.95481 29.7095 11.6507 29.7095C14.3466 29.7095 16.5373 27.5187 16.5373 24.8228C16.5373 24.5124 16.7869 24.2629 17.0972 24.2629C17.4015 24.2629 17.6571 24.5124 17.6571 24.8228C17.6449 28.1211 14.9551 30.817 11.6507 30.817Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M14.8709 25.3685H11.6517C11.3474 25.3685 11.0918 25.119 11.0918 24.8086V21.5833C11.0918 21.2729 11.3413 21.0234 11.6517 21.0234C11.9559 21.0234 12.2115 21.2729 12.2115 21.5833V24.2548H14.877C15.1812 24.2548 15.4368 24.5043 15.4368 24.8147C15.4368 25.1251 15.1752 25.3685 14.8709 25.3685Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M23.231 42.4244H8.05987C7.7556 42.4244 7.5 42.1749 7.5 41.8646C7.5 41.5542 7.74951 41.3047 8.05987 41.3047H23.231C23.5353 41.3047 23.7909 41.5542 23.7909 41.8646C23.7909 42.1749 23.5414 42.4244 23.231 42.4244Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <path
      d="M49.3619 42.4244H34.1907C33.8865 42.4244 33.6309 42.1749 33.6309 41.8646C33.6309 41.5542 33.8804 41.3047 34.1907 41.3047H49.3619C49.6662 41.3047 49.9218 41.5542 49.9218 41.8646C49.9218 42.1749 49.6662 42.4244 49.3619 42.4244Z"
      :fill="active ? 'rgba(202, 82, 86, 1)' : 'black'" />
    <defs>
      <linearGradient id="paint0_linear_6103_69698" x1="2.70937" y1="24.8133" x2="20.5862" y2="24.8133"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#F26522" />
        <stop offset="0.2497" stop-color="#F47D23" />
        <stop offset="1" stop-color="#F47920" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SendingIcon',
  props: ['active'],
}
</script>

<style>
</style>
